
import React from 'react';
import {BrowserRouter, Route, Routes, useRouteError} from "react-router-dom";
import {Homepage} from "./components/Homepage";
//import {About} from "./components/About";
import {Top100} from "./components/Top100";
//import {Contact} from "./components/Contact";
//import {NavigationBar} from "./components/NavigationBar";
import {Footer} from "./components/Footer";


import styled from "styled-components";

const StyledFooter = styled(Footer)`
  color: brown;
  background-color : #222;
  padding-top: 0;
  //position:;
  bottom:0;
  width:100%;
;
`;
const App: React.FC = () => {

    return (
        <div className={"App"}>

            <React.Fragment>
                {/*<Layout>*/}
                <BrowserRouter basename="/">
                    <Routes>
                        <Route path={"/"} element={<Homepage/>} errorElement={<ErrorBoundary />}/> ;
                        <Route path={"top100"} element={<Top100/>} errorElement={<ErrorBoundary />}/>;
                    </Routes>
                </BrowserRouter>
                {/*</Layout>*/}
                <StyledFooter/>
            </React.Fragment>
        </div>
    );
};

function ErrorBoundary() {
    let error = useRouteError();
    console.error(error);
    let div: JSX.Element =
        <div>
            <p>
                <h4>ERROR PAGE NOT FOUND</h4>
            </p>
        </div>;
    return     div;
}



export default App;
