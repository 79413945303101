import React from 'react';
import logo from '../logo.svg';
import './Homepage.css';
export const Homepage = () => {
    const element = (
        <div className={"Homepage"}>
            <header className={"Homepage-header"}>
                <img src={logo} className="Homepage-logo" alt="logo" />
                <p>
                    Expedited Dual Commerce Coming 2024
                </p>
            </header>
        </div>
    );
    return (
        element
     )
}





