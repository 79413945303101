import React from 'react';
import top100Image from "./Top100.png";
import './Top100.css';

export const Top100 = () => (
    <div className="Top100">
        <header className="Top100-header">
            <img src={top100Image}
                 className="Top100-logo" alt="logo"/>
        </header>
    </div>
)
